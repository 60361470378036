import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import useVideoContext from "../../hooks/useVideoContext";
import useInviteNotificationState from "../../hooks/useInviteNotificationState";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { requestStartSession, requestEndSession } from "../../api/httpapi";
import { datadogEvent } from "../../datadog";
import Panel from "../Panel";
import LocalVideoPreview from "../LocalVideoPreview";
import InviteToSessionModal from "../InviteToSessionModal";
import InviteSentNotification from "../InviteSentNotification";
import WaitingRoomShare from "../WaitingRoomShare";
import VideoAndMicSettings from "../VideoAndMicSettings";
import Header from "./Header";
import ClientList from "./ClientList";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  position: relative;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const VideoWrapper = styled.div``;

const Instructions = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    text-align: left;
  }
`;

const ClientsWrapper = styled.div`
  flex: 1;
`;

const TherapistVideoPanel = ({ setStartSessionFailed }) => {
  const { isAcquiringLocalTracks, localTrackError } = useVideoContext();
  const [changingMediaSettings, setChangingMediaSettings] = useState(false);
  const [loadingIdentity, setLoadingIdentity] = useState("");
  const componentIsMounted = useRef(true);
  const {
    handleNotificationModalClose,
    showInviteModal,
    setShowInviteModal,
    inviteResult,
    clientName,
  } = useInviteNotificationState();
  const [instructionsText] = useCopy(["setupCheck.instructions"]);

  const {
    state: { websocket, waitingRoom },
  } = useAppStateContext();

  const disableChat = !websocket.isConnected || !waitingRoom.therapistAvailable;

  const disableStart =
    isAcquiringLocalTracks || localTrackError || !websocket.isConnected;

  const startSession = async ({ participantId }) => {
    setStartSessionFailed(false);
    setLoadingIdentity(participantId);

    const result = await requestStartSession([participantId]);
    if (!result) {
      console.error("Something went wrong starting session");
      setLoadingIdentity("");
      setStartSessionFailed(true);
      return;
    }

    // wait a few seconds and if the component is still mounted,
    // it means that the session could not be started and in that
    // case show an error and end the session
    setTimeout(() => {
      if (componentIsMounted.current) {
        console.error("Therapist did not receive the session ready message");

        setLoadingIdentity("");
        setStartSessionFailed(true);
        requestEndSession();

        datadogEvent({
          category: "session",
          event: "ended",
          component: "TherapistVideoPanel",
        });
      }
    }, 10000);
  };

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const toggleMediaSettings = active => {
    setChangingMediaSettings(active);
  };

  return (
    <Wrapper>
      <VideoAndMicSettings
        active={changingMediaSettings}
        overlap={true}
        onClose={() => toggleMediaSettings(false)}
        testsEnabled={true}
      />
      <InviteSentNotification result={inviteResult} name={clientName} />
      {showInviteModal && (
        <InviteToSessionModal close={handleNotificationModalClose} />
      )}
      <Panel>
        <Content>
          <Column>
            <Header />
            <VideoWrapper>
              <LocalVideoPreview
                showMediaControls={true}
                onMediaSettingsOpen={() =>
                  toggleMediaSettings(!changingMediaSettings)
                }
                isTherapist={true}
              />
            </VideoWrapper>
            <Instructions>{instructionsText}</Instructions>
            <ClientsWrapper>
              <ClientList
                chatDisabled={disableChat}
                startDisabled={disableStart}
                startSession={startSession}
                loadingIdentity={loadingIdentity}
              />
            </ClientsWrapper>
            <WaitingRoomShare onSendInvite={() => setShowInviteModal(true)} />
          </Column>
          <Column />
        </Content>
      </Panel>
    </Wrapper>
  );
};

export default TherapistVideoPanel;
