import React from "react";
import styled, { css } from "styled-components";
import useVideoContext from "../../hooks/useVideoContext";
import useMainSpeaker from "../../hooks/useMainSpeaker";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import { useRect } from "@sussex/react-kit/hooks";
import useInactive from "../../hooks/useInactive";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import useVideoTrackDimensions from "../../hooks/useVideoTrackDimensions";
import ParticipantControls from "../ParticipantControls";
import Participant from "../Participant/";
import ParticipantInfo from "../ParticipantInfo";

const ParticipantWrapper = styled.div`
  width: auto;
  height: calc(100% - 90px);
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.dark};
  border-radius: 16px;
  margin: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: calc(100% - 115px);
  }

  & > div {
    width: 100%;
    height: 100%;
  }

  & video {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
    object-fit: ${({ fit }) => fit || "contain"};
  }
`;

const InfoWrap = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  ${({ hideParticipantInfo }) =>
    hideParticipantInfo
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `};
`;

export default function MainParticipant({ isTherapist, hideParticipantInfo }) {
  const mainParticipant = useMainSpeaker();
  const publications = usePublications(mainParticipant);
  const audioPublication = publications.find(p => p.kind === "audio");

  const [componentSize, componentRef] = useRect();

  const audioTrack = useTrack(audioPublication);
  const isInactive = useInactive(3000);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);
  const {
    room: { localParticipant },
    selectedParticipant,
    setSelectedParticipant,
    screenShareParticipant,
    sendMessage,
  } = useVideoContext();
  const { displayName } = useParticipantInfo(mainParticipant.identity);
  const screenTrack = Array.from(mainParticipant.tracks.values()).find(
    track => {
      return track.trackName.includes("screen");
    },
  );
  const cameraTrack = Array.from(mainParticipant.tracks.values()).find(
    track => {
      return track.trackName.includes("camera");
    },
  );
  const cameraTrackDimensions = useVideoTrackDimensions(cameraTrack?.track);

  const width = cameraTrackDimensions?.width || 0;
  const height = cameraTrackDimensions?.height || 0;

  const videoIsLandscape = width >= height;
  const componentIsLandscape = componentSize.width >= componentSize.height;

  const isScreenshare = typeof screenTrack !== "undefined";

  const toggleMuteParticipant = () => {
    const message = isAudioEnabled ? "mute" : "unmute";

    sendMessage({
      type: message,
      to: mainParticipant.identity,
    });
  };

  const removeParticipant = () => {
    sendMessage({
      type: "kick",
      to: mainParticipant.identity,
    });
  };
  const pinParticipant = () => {
    setSelectedParticipant(mainParticipant);
  };

  const showAdminControls = isTherapist && mainParticipant !== localParticipant;

  const fit = screenshare => {
    // Screenshare always contain the video
    if (screenshare) {
      return "contain";
    }
    // If the video container is vertically smaller than 400px, we want to
    // contain the video
    if (componentIsLandscape && componentSize.height <= 400) {
      return "contain";
    }

    // If the aspect ratios match we want to cover the video
    if (videoIsLandscape === componentIsLandscape) {
      return "cover";
    }

    return "contain";
  };

  return (
    <ParticipantWrapper ref={componentRef} fit={fit(isScreenshare)}>
      <div>
        <Participant
          participant={mainParticipant}
          isMain={true}
          enableScreenShare={true}
          showAdminControls={showAdminControls}
          isScreenshare={isScreenshare}
          full
        >
          <InfoWrap hideParticipantInfo={hideParticipantInfo}>
            <ParticipantInfo participant={mainParticipant} />
            <ParticipantControls
              isMain={true}
              isVisible={!isInactive}
              showAdminControls={showAdminControls}
              isSelected={selectedParticipant === mainParticipant}
              setSelected={pinParticipant}
              isAudioEnabled={isAudioEnabled}
              toggleMuteParticipant={toggleMuteParticipant}
              showPinControls={
                !screenShareParticipant ||
                screenShareParticipant === localParticipant
              }
              removeParticipant={removeParticipant}
              identity={mainParticipant.identity}
              displayName={displayName}
            />
          </InfoWrap>
        </Participant>
      </div>
    </ParticipantWrapper>
  );
}
